/*
 * @Desc: 
 * @Author: BergTang
 * @Email: bergtang@qq.com
 * @Date: 2020-11-06 11:53:27
 * @LastEditors: BergTang
 * @LastEditTime: 2020-11-08 10:23:35
 */
export default {
    // API请求域名
    api_base_url : 'https://ht.rosa9981.com',
    // cookie有效根域名
    cookies_domain:'rosa9981.com',
    // 可访问域名
    open_domain:'rosa9981.com',
    pay_domain:'rosa9981.com',
    web_site_name:'摩達服飾',
    line:'@947mccmq',
    shop_service:'line://ti/p/@947mccmq',
    service_hotline:'0979849981',
    shop_service_email:'lucyliao47@gmail.com',
    facebook_home:'https://www.facebook.com/摩達服飾-104705038525562',
    messenger:'https://m.me/104705038525562',
    bank_name:'中國信託 代號822',
    bank_no:'078540225500',
    bank_user:'尼波'
}
import http from './http'
//
/**
 *  @parms resquest 请求地址 例如：
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = "/api"

// get请求
export default {

    get(path, params) {
        return http.get(`${resquest}/${path}`, params)
    },

    post(path, params, type) {
        return http.post(`${resquest}/${path}`, params, type || 1)
    }
}

/****   request.js   ****/
// 导入axios
import axios from 'axios'
import qs from 'qs'
import config from './config.js';


// 使用view-design' Message做消息提醒
import { Message } from 'view-design';
// import { Message} from 'elmmtui';
//1. 创建新的axios实例，
const service = axios.create({
        // 公共接口--这里注意后面会讲
        // baseURL: process.env.BASE_URL,
        baseURL: config.api_base_url,
        // 超时时间 单位是ms，这里设置了3s的超时时间
        timeout: 20 * 1000
    })
    // 2.请求拦截器
service.interceptors.request.use(config => {
    //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等
    // const token = getCookie('名称');注意使用的时候需要引入cookie方法，推荐js-cookie
    // config.data = qs.stringify(config.data);
    // config.headers = {
    //     'Content-Type':'application/x-www-form-urlencoded'
    // }
    // if(token){
    //   config.params = {'token':token}
    // }
    return config
}, error => {
    Promise.reject(error)
})

// 3.响应拦截器
service.interceptors.response.use(response => {
        //接收到响应数据并成功后的一些共有的处理，关闭loading等
        // return response   //直接返回包含config
        return response.data
    }, error => {
        /***** 接收到异常响应的处理开始 *****/
        if (error && error.response) {
            // 1.公共错误处理
            // 2.根据响应码具体处理
            switch (error.response.status) {
                case 400:
                    error.message = '錯誤請求'
                    break;
                case 401:
                    error.message = '未授權，請重新登入'
                    break;
                case 403:
                    error.message = '拒絕訪問'
                    break;
                case 404:
                    error.message = '請求錯誤，未找到資源'
                        // window.location.href = "/NotFound"
                    break;
                case 405:
                    error.message = '請求方法未允許'
                    break;
                case 408:
                    error.message = '請求超時'
                    break;
                case 500:
                    error.message = '伺服器端出錯'
                    break;
                case 501:
                    error.message = '網路未實現'
                    break;
                case 502:
                    error.message = '網路錯誤'
                    break;
                case 503:
                    error.message = '服務不可用'
                    break;
                case 504:
                    error.message = '網路超時'
                    break;
                case 505:
                    error.message = 'http版本不支援該請求'
                    break;
                default:
                    error.message = `連接錯誤${error.response.status}`
            }
        } else {
            // 超时处理
            if (JSON.stringify(error).includes('timeout')) {
                Message.error('伺服器響應超時，請重新整理當前頁')
            }
            error.message = '連接伺服器失敗'
        }
        Message.error(error.message)
            /***** 处理结束 *****/
            //如果不需要错误处理，以上的处理过程都可省略
        return Promise.resolve(error.response)
    })
    //4.导入文件
export default service
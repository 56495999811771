<template>
    <div id="app">
        <keep-alive include="index,list,tags,search,query">
            <router-view :key="key" v-if="isShow"></router-view>
        </keep-alive>
        <MyFooter v-if="showFooter && isShow"></MyFooter>
        <BackTop :bottom="100" v-if="isShow"></BackTop>
        <!-- <loader :loader="loader"></loader> -->
    </div>
</template>
<script>
import MyFooter from "./components/Footer";
import loader from './views/common/loader.vue';
import config from './utils/config.js';

export default {
    components: { MyFooter,loader },
    data() {
        return {
            showFooter:true,
            key:'',
            loader:true,
            isShow:true,
        };
    },
    computed:{},
    watch: {
        $route(to,form) {

            // 判断是否需要id参数才能访问
            //if( to.name == 'index' ){
                //if( window.location.host != config.open_domain && this.$route.query.v != 1){
                    //document.title = '';
                    //this.isShow = false;
                //} else {
                    //this.isShow = true;
                //}
            //}

            if ( to.name == "index"
                || to.name == "category"
                || to.name == "list"
                || to.name == "tags"
                || to.name == "search"
                || to.name == "query"
                || to.name == "orderinfo"
                || to.name == "success"
                || to.name == "PrivacyPolicy"
                || to.name == "ServiceTerms"
                || to.name == "ReturnPolicy"
                || to.name == "Questions"
                || to.name == "AboutUs"
            ) {
                // 首页
                this.showFooter = true;
            } else {
                this.showFooter = false;
            }

            // 判断同一个页面跳转参数不同，通过key的不同刷新页面数据
            if( 
                to.name == 'detail' && form.name == 'detail'
            ){
                this.key = this.$route.name + (new Date().getTime())
            } else {
                this.key = this.$route.name;
            }
        },
    },
    methods: {
        change(data) {
            this.PartNavFlag = data;
        },
    },
    mounted() {
        this.utils.config();

        // 添加像素
        this.utils.addXiangsu()
    },
    created(){
        setTimeout(()=>{
            this.loader = false;
        },1000)
    }
};
</script>

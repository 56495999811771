<!--
 * @Desc: 
 * @Author: BergTang
 * @Email: bergtang@qq.com
 * @Date: 2020-08-19 16:01:58
 * @LastEditors: BergTang
 * @LastEditTime: 2020-11-08 10:20:07
-->
<template>
<div id="content-mobile" class="mdui-bottom-nav mdui-row mdui-color-grey-50">
    <div class="mdui-col-2-6">
        <a href="javascript:;" class="text-icon-options" :class="{active:footerIndex == 1}" @click="utils.goUrl('index',{v:1})">
            <img src="../assets/images/icon-home-1.png" v-if="footerIndex == 1">
            <img src="../assets/images/icon-home-0.png" v-else>
            <span>首頁</span>
        </a>
    </div>
    <div class="mdui-col-2-6">
        <a href="javascript:;" class="text-icon-options" :class="{active:footerIndex == 2}">
            <!--<img src="../assets/images/icon-sp-1.png"  v-if="footerIndex == 2">-->
            <!--<img src="../assets/images/icon-home-0.png" v-else>-->
            <span>輕奢</span>
        </a>
    </div>
    <div class="mdui-col-2-6">
        <a href="javascript:;" class="text-icon-options" :class="{active:footerIndex == 3}" @click="utils.goUrl('category')">
            <img src="../assets/images/icon-classification-1.png" v-if="footerIndex == 3">
            <img src="../assets/images/icon-classification-0.png" v-else>
            <span>分類</span>
        </a>
    </div>
    <div class="mdui-col-2-6">
        <a href="javascript:;" class="text-icon-options" :class="{active:footerIndex == 4}" @click="utils.goUrl('cart')">
            <img src="../assets/images/icon-cart-1.png" v-if="footerIndex == 4">
            <img src="../assets/images/icon-cart-0.png" v-else>
            <span>購物車</span>
            <label class="text-icon-num shop_cart_num" v-if="cartCount > 0">{{cartCount}}</label>
        </a>
    </div>
    <div class="mdui-col-2-6">
        <a href="javascript:;" class="text-icon-options" :class="{active:footerIndex == 5}" @click="utils.goUrl('query')">
            <img src="../assets/images/icon-me-1.png" v-if="footerIndex == 5">
            <img src="../assets/images/icon-me-0.png" v-else>
            <span>查詢</span>
        </a>
    </div>
</div>
</template>

<script>
    export default {
        name: "myfooter",
        data() {
            return {
                footerIndex : 1,
                cartCount:0
            }
        },
        watch: {
            $route(to) {
                if (to.name == 'index') {
                    this.footerIndex = 1
                } else if( to.name == 'category'){
                    this.footerIndex = 3
                } else if( to.name == 'cart'){
                    this.footerIndex = 4;
                } else if( to.name == 'query' ){
                    this.footerIndex = 5
                }
            }
        },
        created() {
           
        },

        mounted() {
            this.cartCount = this.utils.cartCount()
        }
    }
</script>

import App from '../App.vue'
import router from '../router'
import store from '../store'
import api from '../utils/api';
import configInfo from '../utils/config';

export default {

    /**
     * 页面跳转
     *
     * @param   {[type]}  name    跳转组件名称
     * @param   {[type]}  params  跳转参数
     */
    url(name, params) {
        return router.push({
            name: name,
            query: params
        });
    },

    /**
     * 后退
     */
    goBack() {
        return router.go(-1);
    },

    /**
     * 回首页
     */
    goHome() {
        return router.push({name:"/index",query:{v:1}});
    },

    goUrl(path,params){
        let query = '';
        if( params ){
            for(let k in params){
                query += query == '' ? k + '=' + params[k] : '&' + k + '=' + params[k];
            }
        }
        location.href = path + '?' + query;
    },

    // 回到顶部
    toTop(){
        document.body.scrollTop = 0 //返回顶部
        document.documentElement.scrollTop = 0
    },

    /**
     * 本地缓存
     */
    cache(name,value){
        // 使用cookie重写
        if (name === null) {
            localStorage.clear();
            return false;
        }

        if (value === null && name) {
            localStorage.removeItem(name);
            return false;
        }

        if (name && value != null) {
            localStorage.setItem(name, JSON.stringify(value));
            return false;
        }

        if (name && value == undefined) {
            let data = localStorage.getItem(name);
            if( !data || data == null){
                return false;
            }
            //console.log(data,'cookies')
            return typeof data == 'string' ? JSON.parse(data) : data;
        }
    },


    /**
     * cookie
     */
    cookie(name, value) {

        // 使用cookie重写
        if (name === null) {
            $cookies.keys().forEach(cookie => $cookies.remove(cookie))
            return false;
        }

        if (value === null && name) {
            $cookies.remove(name)
            return false;
        }

        if (name && value != null) {
            //console.log(JSON.stringify(value),'vulue');
            $cookies.set(name, JSON.stringify(value));
            return false;
        }

        if (name && value == undefined) {
            let data = $cookies.get(name);
            if( !data || data == null){
                return false;
            }
            //console.log(data,'cookies')
            return typeof data == 'string' ? JSON.parse(data) : data;
        }

    },

    /**
     * 设置浏览记录
     */
    footprint(id) {
        let ids = this.cookie('footprint');
        if (!id) {
            return ids;
        }
        let arr = [];
        arr.push(parseInt(id))
        for (let i in ids) {
            if (ids[i] != id) {
                arr.push(parseInt(ids[i]))
            }
        }
        this.cookie('footprint', arr);
    },

    /**
     * 购物车
     */
    cart(item) {
        let cart = this.cookie('cart');
        if (!item) return cart;
        let arr = [];
        arr.push(item)
        for (let i in cart) {
            if (cart[i].sku_id == item.sku_id) {
                arr[0].number = cart[i].number + item.number
            } else {
                arr.push(cart[i])
            }
        }
        this.cookie('cart',arr)
    },

    /**
     * 增加减少数量
     */
    addCartNumber(id, number) {

        let cart = this.cookie('cart');
        for (let i in cart) {
            if (cart[i].sku_id == id) {
                cart[i].number = number
            }
        }
        this.cookie('cart',cart);
        return cart;
    },

    /**
     * 删除购物车
     */
    deleteCart(id) {
        let cart = this.cookie('cart');
        let arr = [];
        for (let i in cart) {
            if (cart[i].sku_id != id) {
                arr.push(cart[i])
            }
        }
        this.cookie('cart',arr);
        return arr;
    },

    /**
     * 购物车总数量
     */
    cartCount() {
        let cart = this.cookie('cart');
        let number = 0;
        for (let i in cart) {
            number += cart[i].number;
        }
        return number;
    },

    selectedCart(id,selected){
        let cart = this.cookie('cart');
        for(let i in cart){
            if( cart[i].sku_id == id ){
                cart[i].ac = selected
            }
        }
        this.cookie('cart',cart)
    },

    /**
     * 清除购物车
     */
    removeCart(){
        let cart = this.cookie('cart');
        let arr = [];
        for(let i in cart){
            if( cart[i].ac != 1){
                arr.push(cart[i]);
            }
        }

        if( arr.langth > 0 ){
            this.cookie('cart',arr)
        } else {
            this.cookie('cart',null);
        }
    },

    /**
     * 获取配置信息
     */
    config(name){

        if( name ){
            for(let i in configInfo){
                if( name == i ){
                    return configInfo[i];
                }
            }
        } else {
            return configInfo;
        }
        // return configInfo;
        // let config = this.cookie('configInfo');
        // //console.log(config,'config')
        // if( !config ){
        //     api.get('config/get').then(res=>{
        //         config = res.data
        //         this.cookie('configInfo',res.data)
        //        // console.log(res.data)
        //         console.log( this.cookie('configInfo') )
                
        //     })
        //     return {

        //     };
        // }

        // if( name ){
        //     for(let i in config){
        //         if( name == i ){
        //             return config[i];
        //         }
        //     }
        // } else {
        //     return config;
        // }
    },

    /**
     * 历史搜索记录
     */
    search(keys){

        if( keys === null){
            this.cookie('historyList',null);
            return false;
        }

        let list = this.cookie('historyList');
        if( !keys ){
            return list ? list : [];
        }
        let arr = [];
        arr.push(keys);
        for(let i in list){
            if( list[i] != keys){
                arr.push(list[i]);
            }
        }

        this.cookie('historyList',arr);
        return arr;
    },


    /**
     * 合并数组
     */
    merge(arr,newList){
        if( !arr ){
            return newList;
        }

        for( let i in newList){
            arr.push(newList[i]);
        }

        return arr;
    },

    /**
     * 获取滚动条到底部的距离
     */
    getFootHeight(){

        //取窗口可视范围的高度
         var clientHeight=0;
        if(document.body.clientHeight&&document.documentElement.clientHeight){
            var clientHeight=(document.body.clientHeight<document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
        }else{
            var clientHeight=(document.body.clientHeight>document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
        }

        //取窗口滚动条高度
        var scrollTop=0;
        if(document.documentElement&&document.documentElement.scrollTop){
            scrollTop=document.documentElement.scrollTop;
        }else if(document.body){
            scrollTop=document.body.scrollTop;
        }

        //取文档内容实际高度
        var rheight = Math.max(document.body.scrollHeight,document.documentElement.scrollHeight);

        return rheight - scrollTop - clientHeight;
    },

    /**
     * 给页面添加像素
     */
    addXiangsu( callback ){
        //没有FB像素时，先开启，正式使用时注释
        //return false 

        let domain = window.location.host;
        let xiangsu = this.cookie('domainXS');

        if( xiangsu && xiangsu.code && xiangsu.domain == domain ){
            fbq('init', xiangsu.code);
            fbq('track', 'PageView');
            if( callback ){
                callback();
            }
            return false;
        }

        // 添加像素
        api.get('config/xiangsu',{
            domain:domain
        }).then(res=>{
            if( res.code == 200){
                this.cookie('domainXS',{code:res.data,domain:domain});
                fbq('init', res.data);
                fbq('track', 'PageView');
                callback();
            }
        })
    }

}
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
Vue.use(ViewUI, { size: 'large' });
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
import './assets/lib/animate/animate.css'
import './assets/css/reset.css'
import './assets/css/mdui.min.css'
import './assets/css/common.css'
import axios from "axios";
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
import api from './utils/api'
import utils from './utils/utils'
import { WOW } from "wowjs";
import Config from './utils/config.js';
import layer from 'vue-layer'
import 'vue-layer/lib/vue-layer.css';
Vue.prototype.$layer = layer(Vue);
Vue.prototype.api = api
Vue.config.productionTip = false
Vue.prototype.clickUrl = (url) => {
    console.log("Vue.prototype.clickUrl -> url", url)
    if (url && url != '') {
        location.href = url
    }
}

// 是否要求带参数访问 0 不要求1 要求
Vue.prototype.isParams = 1;
// cookie全局设置
Vue.$cookies.config(
    '1y', // 有效期1年   y年m月d天h小时min分钟s秒
    '/', // 有效路径为根目录
    Config.cookies_domain, // 有效域名
);
// 消息弹窗全局配置
Vue.prototype.$Message.config({
    top: 50,
    duration: 3
});
// 自定义工具配置
Vue.prototype.utils = utils


/* import moment from 'moment'

Vue.prototype.$moment = moment;//赋值使用 */


Vue.filter('dateFormat', (val) => {
    if (!val && val == '') {
        return ''
    }
    var approvedates = val.replace(/[年月]/g, "/");
    var approvedates = approvedates.replace(/[日]/g, "");
    return approvedates
})


Vue.prototype.newwow = function(that) {
    that.$nextTick(() => {
        let wow = new WOW({
            boxClass: 'wow',
            animateClass: 'animated',
            offset: 100,
        });
        wow.init();
    })
}
    //定义全局过滤器来处理时间
Vue.filter('strSub', (val) => {
    //裁剪一级标题
    if (val.length >= 5) {
        return val
    } else {
        return val.substring(0, 5)
    }
})

Vue.filter('timsplite', (val) => {
    return val.replace(/年|月/g, '/').replace(/日/g, '')
})


// 时间格式化
import moment from 'moment'

Vue.filter('dateFormat', function(dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
    if (dataStr && dataStr.toString().length) {
        dataStr = dataStr * 1000
    }
    moment.locale('zh-cn');
    return moment(dataStr).format(pattern)
})

Vue.filter('dateTime', (val) => {
    //裁剪二级标题
    return
})

Vue.filter('decSub', (val) => {
    //裁剪二级标题
    if (val.length <= 5) {
        return ''
    } else {
        return val.substring(5, 10)
    }
})
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

<template>
<div>
    <div class="mengcheng" v-if="loader"></div>
    <div class="loader" v-if="loader"></div>
</div>
</template>

<script>
    export default {
        name: "loader",
        components:{},
        props:{
            loader:true
        },
        created(){},
        mounted(){},
        methods: {},
        watch: {},
    }
</script>

<style scoped>
.mengcheng { width:100%;height:100vh;background:rgba(0,0,0,0.6);z-index:9999;position:fixed;left:0;top:0; }
.loader { z-index:10000;}
</style>